import React, { useContext, useRef, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./terms_and_conditions.scss"
import Col9 from "../grid/Col9"
import Col3 from "../grid/Col3"
import "./animation.scss"
import Col5 from "../grid/Col5"
import Col6 from "../grid/Col6"
import BlockCTA from "../configurable/BlockCTA"
import Col7 from "../grid/Col7"
import { GetModal } from "../configurable/Modal"



const TnC = () => {

  return (
    <div className="refer">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Terms and Conditions
        </Heading>
      </Hero>
      <Block>
      <Section>
          <div className="container">
            <div>
              <Heading level={3} underlined>
                Terms and Conditions Domestic Sales
              </Heading>
              <p>
                Coming soon
              </p>
            </div>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default TnC
